<template>
  <section>
    <div class="product-list-switch">
      <div class="product-list-switch__top">
        <button @click="changeListItems(list1Items, list1Name)" :class="{'link-light': currentListItems === list1Items}">
          {{ item.list1Text }}
        </button>
        <button @click="changeListItems(list2Items, list2Name)" :class="{'link-light': currentListItems === list2Items}">
          {{ item.list2Text }}
        </button>
      </div>
      <div>
        <ul class="product-list-switch__products">
          <li v-for="(itemData,i) in currentListItems" :key="item.id + '-' + activeList + '-' + i">
            <ProductItem :product="itemData.product" :full-width="fullWidthProductItem"></ProductItem>
          </li>
        </ul>
      </div>
      <div class="product-list-switch__bottom">
        <router-link :to="localizedUrl + item.ctaLink" v-if="item.ctaLink && !item.ctaLink.includes('http')" class="btn -stroke">
          {{ item.ctaText }}
        </router-link>
        <a :href="item.ctaLink" v-else-if="item.ctaLink && item.ctaLink.includes('http')" class="btn -stroke">
          {{ item.ctaText }}
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import ProductItem from './ProductItem.vue'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export default {
  props: ['item', 'items', 'pagePosition', 'siblings-components'],
  name: 'ProductListSwitch',
  components: {
    ProductItem
  },
  data() {
    const list1Name = 'LIST_1'
    const list2Name = 'LIST_2'
    const list1Items = this.items.filter(itemData => itemData.referenceList === list1Name)
    const list2Items = this.items.filter(itemData => itemData.referenceList === list2Name)

    return {
      list1Items,
      list2Items,
      list1Name,
      list2Name,
      currentListItems: list1Items,
      activeList: list1Name
    }
  },
  methods: {
    changeListItems(list, activeList) {
      this.currentListItems = list
      this.activeList = activeList
    }
  },
  computed: {
    localizedUrl () {
      return currentStoreView().url || ''
    },
    fullWidthProductItem() {
      return this.currentListItems.length >= 4
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~theme/css/core";

  $btn-font-size: em(16.8);

  .product-list-switch {
    row-gap: 4rem;
    display: flex;
    flex-direction: column;
    margin: 4rem 6rem;

    @include bp(max md) {
      margin: 4rem 0rem;
      row-gap: 2rem;
    }

    &__top {
      display: flex;
      justify-content: center;
      column-gap: 2rem;

      button {
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        font-size: $btn-font-size;
        color: var(--zenith-black);

        &.link-light{
          color: $link-color;
          font-size: $btn-font-size;
          font-weight: $weight-regular;
          letter-spacing: normal;

          &::before{
            background-color: $link-color;
          }
        }
      }
    }

    &__products {
      list-style-type: none;
      display: flex;
      justify-content: center;
      gap: 2rem;
      padding: 0;

      @include bp(max md) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      > li {
        // 100% - gaps divided by number of columns
        flex-basis: calc((100% - 2rem* 3) / 4);

        /deep/ .product {
          .watch {
            height: max-content;
          }

          .tile-information .title {
            font-size: calc(max(min(20%, #{em(12)}), 1em));
          }
        }
      }
    }

    &__bottom {
      display: flex;
      justify-content: center;
    }
  }
</style>
